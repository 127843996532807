<div class="handoff-container equipment-stop-container">
    <div class="col-12 accord-container" [ngClass]="selectedPlantCode ? selectedPlantCode + ' ' + positionCode : ''">

        @for (controlItem of equStopsResponse; track controlItem; let idx = $index) {
        <div class="row position-relative ">
            @for (equStopsContentItem of controlItem; track equStopsContentItem) {
            @if(equStopsContentItem.ctrltype === 'label' && equStopsContentItem.type
            !=='defect'&&(equStopsContentItem.controlname ==="es-tes") ){
            <div class="row py-3 ">
                <label class="safety-header">{{equStopsContentItem.labeltext}}</label>
            </div>
            }
            
            @if((!(selectedPlantCode =='HAR') && (selectedPlantCode === 'BUR' && positionCode === 'PLR' && equStopsContentItem.labeltext!=='Total') && (equStopsContentItem.controlname ==='es-stopsarea-lbl' ||
            equStopsContentItem.controlname ==='es-total-stops' || equStopsContentItem.controlname ==='es-total-lbl' ))
            && equStopsContentItem.ctrltype === "label" ){
            <h4 class="m-0 p-0  mb-2 mt-2 safety-header report-header">{{equStopsContentItem.labeltext}}</h4> 
            }

            @if(((selectedPlantCode =='HAR' && positionCode.includes('EPRL')|| (selectedPlantCode =='CDL' && positionCode.includes('PKG6')) || (selectedPlantCode =='FVL' && positionCode.includes('PKG')) || (selectedPlantCode =='BUR2' && (positionCode.includes('PKGOPR') ||  ['FRYOPR','PEELOPR','BTROPR','SORTOPR','ATNDTSSHB','CTROPR'].includes(positionCode))) || (selectedPlantCode =='BUR1' && (positionCode.includes('PKGOPR') ||  ['FRYOPR','ADROP','SHKROOM','OPRIQFPROC','FRYOPR','CUTOPR','PEELOPR'].includes(positionCode))) ) && (equStopsContentItem.controlname ==='es-stopsarea-lbl' ||
            equStopsContentItem.controlname ==='es-total-stops' ))
            && equStopsContentItem.ctrltype === "label" ){
            <h4 class="m-0 p-0  mb-2 mt-2 safety-header report-header">{{equStopsContentItem.labeltext}}</h4>
            }

            @if(equStopsContentItem.widget ==='input-table-header' && equStopsContentItem.ctrltype === "label" ){
            <div class="col p-1">
                <div class="col p-0 d-flex textbox-content  textbox-wrapper">
                    <label
                        class=" form-control h-75 label-head rounded-0  d-flex row{{idx}}">{{equStopsContentItem.labeltext}}</label>
                </div>
            </div>

            }


            @if(equStopsContentItem.widget ==='input-table' && (equStopsContentItem.ctrltype === "text" ||
            equStopsContentItem.ctrltype === "select")){
            @if(equStopsContentItem.value) {
            <div class="col p-1 target-container ">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                    {{equStopsContentItem.value}}

                </div>
            </div>
            }
            @else if(equStopsContentItem.response) {
            <div class="col p-1 target-container comment-width1 ">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center comment-width11 ">
                    {{equStopsContentItem.response ? equStopsContentItem.response : "" }}

                </div>
            </div>
            }
            @else if(equStopsContentItem.placeholder) {
            <div class="col p-1 target-container">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                    {{equStopsContentItem.placeholder ? equStopsContentItem.placeholder : "" }}

                </div>
            </div>
            }
            @else if(equStopsContentItem.default && equStopsContentItem?.options?.length === 0) {
            <div class="col p-1 target-container">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">

                </div>
            </div>
            }


            }

            @if(equStopsContentItem.widget ==='input-table' && equStopsContentItem.ctrltype === "number")
            {
            @if(equStopsContentItem.response){
            <div class="col p-1 target-container">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                    <div class="d-flex align-items-center col-9">
                        {{equStopsContentItem.response}}
                    </div>
                    @if(equStopsContentItem.unit) {
                    <div class="d-flex align-items-center justify-content-end unit-bg">

                        {{equStopsContentItem.unit}}
                    </div>
                    }

                </div>
            </div>
            } @else if(equStopsContentItem.value){
            <div class="col p-1 target-container">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                    <div class="d-flex align-items-center col-9">
                        {{equStopsContentItem.value}}
                    </div>
                    @if(equStopsContentItem.unit) {
                    <div class="d-flex align-items-center justify-content-end unit-bg">

                        {{equStopsContentItem.unit}}
                    </div>
                    }

                </div>
            </div>
            }
            @else{
            <div class="col p-1 target-container">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                    <div class="d-flex align-items-center col-9">
                        {{equStopsContentItem.response}}
                    </div>
                    @if(equStopsContentItem.unit) {
                    <div class="d-flex align-items-center justify-content-end unit-bg">

                        {{equStopsContentItem.unit}}
                    </div>
                    }

                </div>
            </div>
            }

            }
            @if(equStopsContentItem.ctrltype == "flagicon")
            {
            <span class="enableFlag-{{equStopsContentItem.response ==true ? true:false}}">
            </span>
            }

            }

        </div>
        }


        @if(RTDuetequipmentStops.length > 0){
            @if(selectedPlantCode === 'BUR1' || selectedPlantCode === 'BUR2') {
                <div class="row py-3">
                    <label class="safety-header report-header">{{ "app.previous_hoff.equipment_stops_per_area" |
                        translate }} </label>
                </div>
            }
        @for (RTDueteData of RTDuetequipmentStops; track RTDueteData;let k = $index) {
        @if(groupedByArea.includes(k))
        {

        <div class="col custom-class-head py-1 RTDueteheaderclass">
            <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper">
                <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b ">
                    {{RTDueteData.area}}</label>
            </div>
        </div>
        <div class="row py-3">
            <div class="col custom-class2 ">
                <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper">
                    <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b">{{
                        "app.previous_hoff.area" | translate }}</label>
                </div>
            </div>
            <div class="col custom-class2 ">
                <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper no-overflow-handle">
                    <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b">{{
                        "app.previous_hoff.downtime_start" | translate }}</label>
                </div>
            </div>
            <div class="col custom-class2 ">
                <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper no-overflow-handle">
                    <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b">{{
                        "app.previous_hoff.downtime_end" | translate }}</label>
                </div>
            </div>
            <div class="col custom-class2 ">
                <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper no-overflow-handle">
                    <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b">{{
                        "app.previous_hoff.total_downtime" | translate }}</label>
                </div>
            </div>
            <div class="col custom-class2 ">
                <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper no-overflow-handle">
                    <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b">{{
                        "app.previous_hoff.root_category" | translate }}</label>
                </div>
            </div>
            <div class="col custom-class2 ">
                <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper no-overflow-handle">
                    <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b">{{
                        "app.previous_hoff.comment" | translate }}</label>
                </div>
            </div>
            <div class="col custom-class2 ">
                <div class="custom- col p-0 d-flex textbox-content  textbox-wrapper no-overflow-handle">
                    <label class=" form-control h-75 label-head rounded-0  d-flex justify-content-left b">{{
                        "app.previous_hoff.suggested_next_step" | translate }}</label>
                </div>
            </div>
        </div>
        }
        <div class="row py-1">
            <div class="col p-1 custom-class">
                <div class="col p-0 d-flex textbox-content textbox-wrapper">
                    <label
                        class=" form-control h-75 rounded-0 d-flex justify-content-left custom-comment-length">{{RTDueteData.area}}</label>
                </div>
            </div>
            <div class="col p-1 custom-class">
                <div class="col p-0 d-flex textbox-content textbox-wrapper">
                    <label
                        class=" form-control h-75 rounded-0 d-flex justify-content-left custom-comment-length">{{getDateFromEpoch(RTDueteData.lineOffTime)}}</label>
                </div>
            </div>
            <div class="col p-1 custom-class">
                <div class="col p-0 d-flex textbox-content textbox-wrapper">
                    <label
                        class=" form-control h-75 rounded-0 d-flex justify-content-left custom-comment-length">{{getDateFromEpoch(RTDueteData.lineOnTime)}}</label>
                </div>
            </div>
            <div class="col p-1 custom-class">
                <div class="col p-0 d-flex textbox-content textbox-wrapper">
                    <label
                        class=" form-control h-75 rounded-0 d-flex justify-content-left custom-comment-length">{{RTDueteData.duration}}</label>
                    <span class="badge  text-bg-secondary rounded-0">{{ "app.previous_hoff.min" | translate }}</span>
                </div>
            </div>
            <div class="col p-1 custom-class">
                <div class="col p-0 d-flex textbox-content textbox-wrapper">
                    <label
                        class=" form-control h-75 rounded-0 d-flex justify-content-left custom-comment-length">{{RTDueteData.downtimeCategory}}</label>
                </div>
            </div>
            <div class="col p-1 custom-class">
                <div class=" ">
                    <label
                        class=" form-control h-75 rounded-0 d-flex justify-content-left custom-comment-length">{{RTDueteData.operatorComments}}</label>
                </div>
            </div>
            <div class="col p-1 custom-class ">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper suggested">
                    <label
                        class=" form-control h-75 rounded-0 d-flex justify-content-left custom-comment-length">{{RTDueteData.controls[0].response}}</label>
                    <span class="enablenewFlag-{{RTDueteData.controls[1].response ==true ? true:false}}"></span>
                </div>
            </div>
        </div>
        }
        }
        @else{
        @if (selectedPlantCode =='HAR' ) {
        @if (positionCode === "FRMS" || positionCode.includes('ENSM') || positionCode=='MLGFLK' || positionCode ===
        "TRPL1" ||positionCode === "TRPL2" || positionCode === "PLTRL1" || positionCode === "PLTRL2" || positionCode === 'CTNRL2' || positionCode === 'CTNRL1') {}
        @else if(positionCode.includes('EPRL')) {
        <div class="row">
            <div class="col p-0 mb-2">
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                    "app.previous_hoff.reported" | translate }}
                </div>
            </div>
        </div>
        }
        @else {
        <div class="row">
            <div class="col p-0 mb-2">
                <div class="row py-3">
                    <label class="safety-header report-header">{{ "app.previous_hoff.equipment_stops_per_area" |
                        translate }} </label>
                </div>
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                    "app.previous_hoff.reported" | translate }}
                </div>
            </div>
        </div>
        }
        }
        @else {
          
        <div class="row">
            <div class="col p-0 mb-2">
                @if(selectedPlantCode=='MAT' || (selectedPlantCode=='BUR' && (positionCode=='PKG44' || positionCode === "PKG46")) ||
                selectedPlantCode=='CDL' && (positionCode==="PH" || positionCode.includes('PKG')) || (selectedPlantCode =='FVL' && positionCode.includes('PKG')) || ((selectedPlantCode =='BUR1' || selectedPlantCode =='BUR2') && positionCode.includes('PKGOPR'))) {

                }
                @else{
                <div class="row py-3">
                    <!-- @if(['BTROPR'].includes(positionCode)) {
                    <label class="safety-header report-header">{{ "app.previous_hoff.Total Stops per Area" |
                        translate }} </label>
                    } @else { -->
                    <label class="safety-header report-header">{{ "app.previous_hoff.equipment_stops_per_area" |
                        translate }} </label>
                    <!-- } -->
                </div>
                }
                @if(positionCode && !['PKG44','PKG1','PKG2','PKG3','PKG4','PKG5','PKG46'].includes(positionCode) && !positionCode.includes("PKGOPR") ) {
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{
                    "app.previous_hoff.reported" | translate }}
                </div>
                }

            </div>

        </div>
        }
        }
    </div>


</div>