import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import moment from 'moment';
import { ReportedDefectUiComponent } from '../../../reported-defect-ui/reported-defect-ui.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatetimeFormatPipe } from '../../../../../../../../../../shared/pipes/datetime-format.pipe';

@Component({
  selector: 'app-quality-previous',
  standalone: true,
  imports: [CommonModule,ReportedDefectUiComponent, TranslateModule,DatetimeFormatPipe],
  templateUrl: './quality-previous.component.html',
  styleUrl: './quality-previous.component.scss'
})
export class QualityPreviousComponent implements OnChanges {
  @Input() qualityResponse: any;
  @Input() roleName: any;
  @Input() isSupervisorPosition: any;
  @Input() isOperatorPosition: any;
  @Input() positionCode: any;
  @Input() selectedPlantCode: any;
  @Input() reportedDefectArray:any=[];
  public reportedCategoryDefectArray=[];
  public moment: any = moment;
  
  public isDateType?: boolean;
  public controlName?: string;

  constructor(private datePipe: DatePipe) {}


  ngOnChanges() { 
    this.filterLabel(this.qualityResponse);
    if (this.reportedDefectArray && this.reportedDefectArray.length > 0) {
      this.reportedCategoryDefectArray = this.reportedDefectArray.filter((eachItem: any) => eachItem.impact?.code == 'QLT');
 
    }
  }

  public filterLabel(qualityResponse: any) {
    let data = [];
    for (let i = 0; i < qualityResponse?.length; i++) {
      for (let j = 0; j < qualityResponse[i].length; j++) {
        data.push(qualityResponse[i][j]);
      }
    }

    for (let i = 0; i < Object.keys(data).length; i++) {
      if (data[i].ctrltype === 'date') {
        this.isDateType = true;
        if (data[i].controlName === 'last-fr-inspect-date') {
          this.controlName = 'last-fr-inspect-date';

        }
      }
    }


  }
  public getTime(response: any) {
    if (response) {
      const timeString = response;
      const date = new Date();
      const [hours, minutes] = timeString.split(':');
      date.setHours(Number(hours), Number(minutes));
      return this.datePipe.transform(date, 'shortTime');
    } else {
      return ' ';
    }

  }
}
