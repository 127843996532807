import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FetchResponseApiService } from '../../../../services/fetch-response-api.service';
import { getUserDetails } from '../../../../../../helper/getUserDetails';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ShowConfirmMessageComponent } from '../../../../../../shared/components/modalpopup/show-confirm-message/show-confirm-message.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DataService } from '../../../../../../shared/services/data-service.service';
import { ModalService } from '../../../../../../shared/components/modalpopup/modalservice';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-handoff-header',
  standalone: true,
  imports: [CommonModule, NgMultiSelectDropDownModule, TranslateModule],
  providers: [BsModalService],
  templateUrl: './handoff-header.component.html',
  styleUrl: './handoff-header.component.scss',
})
export class HandoffHeaderComponent implements OnInit {
  @Input() currentHandoff: any;
  @Input() isDisableGlobally: any;
  @Input() saveArray1: any;
  @Input() previousHandOff: any;
  @Input() handOffType: any;
  @Input() test: any;
  @Input() categoryArray: any;
  @Input() isValidForm: any;
  @Input() enableSubmitBtn: boolean = false;
  @Input() enableSaveBtn: boolean = false;
  @Input() isShow: any;
  @Input() fetchedDataTemplate: any;
  @Output() userSelectedPosition: any = new EventEmitter();
  @Output() summaryDetailView: any = new EventEmitter();
  @Output() positionName: any = new EventEmitter();
  @Output() disableGlobalSubmit: any = new EventEmitter();
  @Output() firstPosition: any = new EventEmitter();
  private fetchResponseApiService = inject(FetchResponseApiService);
  private changeDetectorRef=inject(ChangeDetectorRef);
  public enableFinalSubmit: any;
  public isInputChangedSave = false;
  public enableSave = false;
  public isValidFormChange = false;
  public selectedPosition: any;
  public enableSubmit = false;
  public selectedPositions: any;
  public savedPositions: any;
  public positionNames: any;
  public currentRoute: any;
  public userDetailsData: any;
  public toggleSummaryDetailView: boolean = false;
  public subscription: Subscription;
  public modalRef = new BsModalRef<ShowConfirmMessageComponent>;
  public userInfoDetails: any;
  public positionId: any;
  public posName?: string;
  public selectPositionforSave: any;
  public enableSaveTrue = false;
  public enableSubmitTrue = false;
  public enableSubmitTrueTemp = false;
  public userHFResponseData: any;
  public isLoading?: boolean;
  public isHandoffEnabled = true;

  ngOnInit() {
    this.subscription = this.dataService.saveStatusEvent?.subscribe((data: any) => {
      if(data && Object.keys(data).length !== 0) {
        this.enableSubmitTrueTemp = data.submit;
        if (data.isInitialLoad == true) {
          this.enableSaveTrue = false;
          this.enableSubmitTrue = data.submit;
          this.changeDetectorRef.detectChanges();
        }
        else {
          this.enableSaveTrue = true;
          this.enableSubmitTrue = false;
          this.changeDetectorRef.detectChanges();
        }
      }
    });
    const userDetailsJson = sessionStorage.getItem('userDetails');
 
    if (userDetailsJson) {
      try {
        this.userDetailsData = JSON.parse(userDetailsJson);
        this.userInfoDetails = getUserDetails(this.userDetailsData);
      } catch (e) {
        console.error("Failed to parse userDetails JSON:", e);
        this.userDetailsData = {};
        this.userInfoDetails = getUserDetails(this.userDetailsData);
      }
    } else {
      this.userDetailsData = {};
      this.userInfoDetails = getUserDetails(this.userDetailsData);
    }
     
        const selectedItemsJson = sessionStorage.getItem('SelectedItems');
    if (selectedItemsJson) {
      try {
        this.selectedPositions = JSON.parse(selectedItemsJson);
        this.fetchSelectedPositionName(this.selectedPositions);
      } catch (e) {
        console.error("Failed to parse SelectedItems JSON:", e);
        this.selectedPositions = [];
        this.fetchSelectedPositionName(this.selectedPositions);
      }
    } else {
      this.selectedPositions = [];
      this.fetchSelectedPositionName(this.selectedPositions);
    }
    this.getFirstPositionId(this.positionId);
    this.getPositionName(this.posName);
  }

  getFirstPositionId(id: any)
  {if(id) {
    this.getPositionHandoffEnabled(id[0],'id')
    this.firstPosition.emit(id[0]);
  }
}
  getPositionName(name: any) {
    this.positionName.emit(name)
  }


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  constructor(private alertService: DataService,private modalHelperService:ModalService,private modalService: BsModalService, private dataService: DataService, route: ActivatedRoute, private translateService: TranslateService) {
  
    if(route.url){
      route.url.subscribe(() => {
        this.currentRoute = route.snapshot.url[0].path;
      });
    }
  }

  getPositionSelected(positionName: any) {
    let selectedPositionName;
    selectedPositionName = positionName;
    this.posName = selectedPositionName;
    this.getPositionHandoffEnabled(selectedPositionName, 'name');
    this.userSelectedPosition.emit(selectedPositionName);
  }

  toggleView() {
    this.toggleSummaryDetailView = !this.toggleSummaryDetailView;
    this.summaryDetailView.emit(this.toggleSummaryDetailView);

  }

  saveCurrentHandoff(isSave: any, responseUID: any, pKey:any) {
    let tempUserDetails :any = sessionStorage.getItem("userDetails");
    let userDetails =JSON.parse(tempUserDetails)
    let userName = userDetails && userDetails.userProfileName ? userDetails.userProfileName:"";
    let response: any = {
      id: responseUID,
      plantid: this.fetchedDataTemplate?.plantid,
      plantname: this.fetchedDataTemplate?.plantname,
      plantcode:this.getPlantCode(),
      lineid: "lineid",
      linename: "linename",
      roleid: this.userInfoDetails ? this.userInfoDetails.roleId : '',
      rolename: this.userInfoDetails ? this.userInfoDetails.roles : '',
      applicationid: this.fetchedDataTemplate?.applicationid,
      positionid: JSON.stringify(this.selectPositionforSave),
      positionname: this.posName,
      positioncode: this.getPositionCodeFromId(this.selectPositionforSave),
      languagecode: this.fetchedDataTemplate?.languagecode,
      pkey: pKey,
      createdby: userName ? userName : '',
      issubmitted: !isSave,
      isautosubmitted: "false",
      issaved: isSave,
      dddshandofftemplate: { categories: this.categoryArray },
      ActionLog: [],
      createddatetime: "",
      updateddatetime: "",
      handOffResponse: ''
    };
    this.modalService.hide();
    this.isLoading = true;
    this.fetchResponseApiService.saveHandoff(
      getUserDetails(this.userDetailsData),
      response
    ).subscribe({
      next: (data: any) => {
        if (data.isSuccess == true) {
          if (!isSave) {
            this.disableGlobalSubmit.emit(true);
          }
          else if (isSave) {
            this.disableGlobalSubmit.emit(false);
          }
          this.updateUIDSessionAfterSave(this.selectPositionforSave, data.result.handOffResponseId,data.result.pKey);
        }
        
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      },
      error: (err: Error) => {
        if (!isSave) {
          this.disableGlobalSubmit.emit(true);
        }
        else if (isSave) {
          this.disableGlobalSubmit.emit(false);
        }
        sessionStorage.setItem('userResponseId', 'null');
        
        this.isLoading = false;
        this.alertService.pushAlertMessage("Something went wrong while saving", "error");
        this.changeDetectorRef.detectChanges();
      }
    });
    
  }

  getPositionCodeFromId(positionid: any) {

    if (sessionStorage.getItem('SelectedItemsForKPI') !== null) {
      this.savedPositions = JSON.parse(
        sessionStorage.getItem('SelectedItemsForKPI') || ''
      );
    }
    if (this.savedPositions && this.savedPositions.length > 0) {
      let index = this.savedPositions.findIndex((x: { id: any; }) => x.id == positionid);
      if (index >= 0) {
        return this.savedPositions[index]?.value;
      }
      else {
        return '';
      }
    }
    return '';
  }


  getPlantCode() {
    if (this.userInfoDetails && this.userInfoDetails) {
      return this.userInfoDetails?.plantCode
    }
    return '';
  }

  updateUIDSessionAfterSave(id: any, value: any,pKey :any) {
    let userResponseId = { id: id, uid: value ,pkey: pKey };
    sessionStorage.setItem('userResponseId', JSON.stringify(userResponseId));
  }


  submit(isSave: any) {
    let responseData: any;
    let responseUID: any;
    let pKey:any;
    if (this.selectedPositions) {
      let indexForText = this.selectedPositions.findIndex((x: { text: string; }) => x.text == this.posName);
      if (indexForText >= 0) {
        this.selectPositionforSave = this.selectedPositions[indexForText].id;
      }
      else {
        let indexForName = this.selectedPositions.findIndex((x: { name: string; }) => x.name == this.posName);
        if (indexForName >= 0) {
          this.selectPositionforSave = this.selectedPositions[indexForName].id;
        }
      }
    }

    if (sessionStorage.getItem('userResponseId') !== null) {
      responseData = JSON.parse(
        sessionStorage.getItem('userResponseId') || ''
      );
    }
    if (responseData == null || responseData == 'null') {
      responseUID = '';
      pKey='';
    }
    else {
      responseUID = responseData ? responseData.uid : '';
      pKey= responseData ? responseData.pkey : '';
    }
    if (!isSave) 
    {
      const initialState: any = {
        title: this.translateService.instant('app.current_hoff.submit_header_title'),
        message: this.translateService.instant('app.current_hoff.submit_content'),
        ok: this.translateService.instant('app.current_hoff.submit'),
        cancel: this.translateService.instant('app.current_hoff.cancel'),
      };
      this.modalRef = this.modalService.show(ShowConfirmMessageComponent, {
        initialState: initialState,
        class: 'modal-dialog-centered'
      });
      this.modalHelperService.addModalRef(this.modalRef);
      this.modalRef.content?.onSave.subscribe((onSave: any) => {
        if (onSave === true) {
          this.saveCurrentHandoff(isSave, responseUID, pKey);
          this.enableSaveTrue = false;
          this.enableSubmitTrue = false;
          this.changeDetectorRef.detectChanges();
        }
        else {

        }

      })
    }
    else {
      this.saveCurrentHandoff(isSave, responseUID, pKey);
      if (this.enableSubmitTrueTemp) {
        this.enableSubmitTrue = true;
        this.enableSaveTrue = false;
        this.changeDetectorRef.detectChanges();
      }
      else {
        this.enableSaveTrue = false;
        this.enableSubmitTrue = false;
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  fetchSelectedPositionName(positionDetails: any) {
    let positionText: any = [];
    let positionId: any = [];
    positionDetails.forEach((position: any) => {
      positionText.push(position.text);
      positionId.push(position.id);

    });
    this.positionNames = positionText;
    this.positionId = positionId;
    this.posName = this.positionNames[0];
  }

  getFormattedPositionId(position: string): string {
    return position.trim().replace(/\s+/g, '_').toLowerCase();
  }

  
  getPositionHandoffEnabled(value: any, type:string) {
    let selectedPositions;
    if (sessionStorage.getItem('SelectedItemsForKPI') !== null) {
      selectedPositions = JSON.parse(
        sessionStorage.getItem('SelectedItemsForKPI') || ''
      );
    }
    if (selectedPositions && selectedPositions.length > 0) {
      let index;
      if(type=='id')
         index = selectedPositions.findIndex((x: { id: any; }) => x.id == value);
      else {
        index = selectedPositions.findIndex((x: { text: any; }) => x.text == value);
      }

      if (index >= 0) {
        this.isHandoffEnabled =  selectedPositions[index]?.isHandoffEnabled;
        
      }      
    }
  }

}
