<div class="header">
    <p class="curnt-header"><i class="bi bi-arrow-left-short arrow px-1"></i> {{ "app.previous_handoff" | translate }}</p>

</div>
<app-handoff-header 
    [previousHandOff]="responseData" 
    (userSelectedPosition)="getSelectedPosition($event)"
    [handOffType]="'previous'" 
    (summaryDetailView)="getSummaryDetailView($event)"
    (firstPosition)="getFirstPosition($event)"
    (positionName)="getpositionName($event)"
    >
</app-handoff-header>
<ng-container *ngIf="isHandoffEnabled; else newDeb" >
    @if(isLoading) {
        <div class="overlay d-flex align-items-center justify-content-center">
            <div class="overlay__inner">
                <div class="overlay__content">
                    <span class="spinner"></span>
                    <p>{{ "app.previous_hoff.loading" | translate }}...</p>
                </div>
            </div>
        </div>
    } @else {
        @if(responseData) {
            <div class="tab-content mt-3 custom-scroll" id="myTabContent">
                <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="home-tab">
                    <div class="card  rounded-0">
                        <div class="card-body">
                            @if (toggleSummaryDetailView){
                            <app-detailed-view [selectedPlantCode]="selectedPlantCode" [reportedDefectArray]="reportedDefectArray" [tabSelected]="selectedTab" [createdBy]="createdBy" [responseData]="categoryData" [roleName]="role"
                                [templateDetails]="templateDetails" [reportedCLDeviationArray]="reportedCLDeviationArray" [reportedRTDuetDefectObj]="reportedRTDuetDefectObj" [positionCode]="positionCode" [isCIDCompleted]="isCIDCompleted"></app-detailed-view>
                            }
                            @else if(categoryData){
            
                            <app-summary-view [selectedPlantCode]="selectedPlantCode" [reportedDefectArray]="reportedDefectArray" [tabSelected]="selectedTab" [createdBy]="createdBy" [responseData]="categoryData" [roleName]="role"
                                [result]="responseData" [reportedCLDeviationArray]="reportedCLDeviationArray" [reportedRTDuetDefectObj]="reportedRTDuetDefectObj"  [positionCode]="positionCode" [isCIDCompleted]="isCIDCompleted"></app-summary-view>
                            }
            
                        </div>
                    </div>
                </div>
            
            
            
            </div>
            } @else {
                <div class="row ">
                    <div class="col d-flex align-items-center justify-content-center no-data">
                        <p>{{ "app.previous_hoff.no_prev_handoff_submitted" | translate:{positionName: positionName} }}</p>
                    </div>
                </div>
            }
    }
</ng-container>
<ng-template #newDeb>
   <div class="noHandoff no-data">
    {{"app.previous_hoff.No_handoff_associated_with_this_position" | translate }}        
   </div>
</ng-template>
