import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './core/components/layout/header/header.component';
import { FooterComponent } from './core/components/layout/footer/footer.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { Meta } from '@angular/platform-browser';
import { ApplicationInsightsService } from './appInsight.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,HeaderComponent,FooterComponent,SpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private readonly applicationInsightsService = inject(ApplicationInsightsService);
  constructor(private meta: Meta) {}

  ngOnInit() {
  }
  title = 'McCain-app';
}
