
@if(selectedPlantCode == 'HAR' || (selectedPlantCode == 'BUR1' && positionCode !== 'PSA')|| (selectedPlantCode == 'BUR2' && (positionCode !== 'PSA' && positionCode !== 'PKGCMPN')))
{
    <div class="information-container">
        <div class="row">
            <div class="col m-2 info-section-header">
                <span id="information-heading-1" class="heading-span">{{ "app.previous_hoff.information" | translate
                    }}</span>
            </div>
        </div>
        <div class="row">
            @for(category of informationData; track $index;) {
            @if(category.labeltext === 'Date' || category.controlname === 'info-date') {
            <div class="col">
                <div class="qn-question">{{ "app.previous_hoff.date" | translate }} </div>
                <div id="answer-1-date-{{$index}}" class="qn-answer">{{ formateDate(category.response) }}</div>
            </div>
            }
            @if(category.labeltext === 'Shift' || category.controlname === 'info-shift') {
            <div class="col">
                    <div class="qn-question">{{category.labeltext}}</div>
                <div id="answer-1-shift-{{$index}}" class="qn-answer">{{category.response}}</div>
            </div>
            }
            @if(category.labeltext === "Crew" || category.controlname === 'info-crew') {
            <div class="col">
                <div class="qn-question">{{ "app.previous_hoff.crew" | translate }}</div>
                <div id="answer-1-crew-{{$index}}" class="qn-answer">{{category.response}}</div>
            </div>
            }
    
            }
    
        </div>
    
        <div class="row">
            <div class="col-4">
                @if(isSupervisorPosition) {
                    <div class="qn-question">{{ "app.previous_hoff.supervisor" | translate }}</div> 
                }
                @else{
                    <div class="qn-question">{{ "app.previous_hoff.operator" | translate }}</div>
                }
                <div id="info-1-createdBy" class="qn-answer">{{createdBy}}</div>
            </div>
            @for(category of informationData; track $index;) {
    
            @if(category.labeltext === 'Shift Manager' || category.controlname === 'info-shiftmanager') {
            <div class="col-4">
                    <div class="qn-question">{{category.labeltext}}</div>
                <div id="info-1-shiftmanager" class="qn-answer">{{category.response}}</div>
            </div>
            }
            }
    
        </div>
    </div> 


    <div *ngFor="let item of accordianArray;">      
        <div class="row">
        <div class="col {{getHeadName(responseData[(item+(item-1))].type)}}-header m-2">
        <div class="col-md-auto">
        <span [id]="getsummaryId(responseData[(item+(item-1))].type)+'-i'" class="icon-sec {{getHeadName(responseData[(item+(item-1))].type)}}-bg category-sec">
                  {{getAccrdianIconText(responseData[(item+(item-1))].type)}}
        </span>
        </div>
        <div class="col-md-8">
        <span [id]="getsummaryId(responseData[(item+(item-1))].type)+'-h'" class="heading-span">
                  {{getAccrdianIconName(responseData[(item+(item-1))].type) | translate }}
        </span>
        </div>
        <div class="col-md-2">
        <ng-container *ngIf="responseData[(item+(item-1))].type == 'DELIVERY' || responseData[(item+(item-1))].type == 'PEOPLE' || responseData[(item+(item-1))].type == 'COST' || (responseData[(item+(item-1))].type == 'ENVIRONMENT' && isSupervisorPosition)">
        </ng-container>
        <ng-container *ngIf="responseData[(item+(item-1))].type == 'CID'">
        <div class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
        <i class="bi {{CIDdata ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
        </div>
        </ng-container>
        <ng-container *ngIf="!(responseData[(item+(item-1))].type == 'DELIVERY' || responseData[(item+(item-1))].type == 'PEOPLE' || responseData[(item+(item-1))].type == 'COST' || (responseData[(item+(item-1))].type == 'ENVIRONMENT' && isSupervisorPosition) || responseData[(item+(item-1))].type == 'CID')">
        <div [id]="getsummaryId(responseData[(item+(item-1))].type)+'-count-1'" class="defect-sec {{getHeadName(responseData[(item+(item-1))].type)}}-bg justify-content-center align-items-center d-flex">
                    {{getAccrdianIconCount(responseData[(item+(item-1))].type)}}
        </div>
        </ng-container>
        </div>
        </div>
         
            <ng-container *ngIf="responseData[item+item]?.type && responseData[item+item]?.type.length > 0">
        <div class="col {{getHeadName(responseData[item+item].type)}}-header m-2">
        <div class="col-md-auto">
        <span [id]="getsummaryId(responseData[(item+item)].type)+'-i'" class="icon-sec {{getHeadName(responseData[item+item].type)}}-bg category-sec">
                    {{getAccrdianIconText(responseData[item+item].type)}}
        </span>
        </div>
        <div class="col-md-8">
        <span [id]="getsummaryId(responseData[(item+item)].type)+'-h'" class="heading-span">
                    {{getAccrdianIconName(responseData[item+item].type) | translate }}
        </span>
        </div>
        <div class="col-md-2">
        <ng-container *ngIf="responseData[(item+item)].type == 'DELIVERY' || responseData[(item+item)].type == 'PEOPLE' || responseData[(item+item)].type == 'COST' || (responseData[(item+item)].type == 'ENVIRONMENT' && isSupervisorPosition)">
        </ng-container>
        <ng-container *ngIf="responseData[(item+item)].type == 'CID'">
        <div class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
        <i class="bi {{CIDdata ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
        </div>
        </ng-container>
        <ng-container *ngIf="!(responseData[(item+item)].type == 'DELIVERY' || responseData[(item+item)].type == 'PEOPLE' || responseData[(item+item)].type == 'COST' || (responseData[(item+item)].type == 'ENVIRONMENT' && isSupervisorPosition) || responseData[(item+item)].type == 'CID')">
        <div [id]="getsummaryId(responseData[(item+item)].type)+'-count-1'" class="defect-sec {{getHeadName(responseData[(item+item)].type)}}-bg justify-content-center align-items-center d-flex">
                      {{getAccrdianIconCount(responseData[(item+item)].type)}}
        </div>
        </ng-container>
        </div>
        </div>
        </ng-container>
         
            <ng-container *ngIf="!responseData[item+item]?.type || responseData[item+item]?.type.length == 0">
        <div class="col m-2"></div>
        </ng-container>
        </div>
        </div>
   
}
@else{
@if(isSupervisorPosition) {
    <div class="information-container">
        <div class="row">
            <div class="col m-2 info-section-header">
                <span id="information-heading-2" class="heading-span">{{ "app.previous_hoff.information" | translate
                    }}</span>
            </div>
        </div>
        <div class="row">
            @for(category of informationData; track $index;) {
            @if(category.labeltext === 'Date' || category.controlname === 'info-date') {
            <div class="col">
                <div class="qn-question">{{ "app.previous_hoff.date" | translate }} </div>
                <div id="answer-date-2-{{$index}}" class="qn-answer">{{formateDate(category.response)}}</div>
            </div>
            }
            @if(category.labeltext === 'Shift' || category.controlname === 'info-shift') {
            <div class="col">
                <div class="qn-question">{{ "app.previous_hoff.shift" | translate }}</div>
                <div id="answer-shit-2-{{$index}}" class="qn-answer">{{category.response}}</div>
            </div>
            }
            @if(category.labeltext === "Crew" || category.controlname === 'info-crew') {
            <div class="col">
                <div class="qn-question">{{ "app.previous_hoff.crew" | translate }}</div>
                <div id="answer-crew-2-{{$index}}" class="qn-answer">{{category.response}}</div>
            </div>
            }
    
            }
    
        </div>
    
        <div class="row">
            <div class="col-4">
                <div class="qn-question">{{ "app.previous_hoff.supervisor" | translate }}</div>
                <div id="info-2-createdBy" class="qn-answer">{{createdBy}}</div>
            </div>
            @for(category of informationData; track $index;) {
    
            @if(category.labeltext === 'Shift Manager' || category.controlname === 'info-shiftmanager') {
            <div class="col-4">
                <div class="qn-question">{{category.labeltext}}</div>
                <div id="info-2-shitmanager" class="qn-answer">{{category.response}}</div>
            </div>
            }
            }
    
        </div>
    </div>
} @else if(isOperatorPosition) {
    <div class="information-container">
        <div class="row">
            <div class="col m-2 info-section-header">
                <span id="information-heading-3" class="heading-span">{{ "app.previous_hoff.information" | translate
                    }}</span>
            </div>
        </div>
        <div class="row mb-3">
    
            @for(category of informationData; track $index;) {
    
            @if(category.labeltext === 'Date' || category.controlname === 'info-date') {
    
            <div class="col">
    
                <div class="qn-question">{{category.labeltext}} </div>
    
                <div id="answer-3-date-{{$index}}" class="qn-answer">{{formateDate(category.response)}}</div>
    
            </div>
    
            }
    
            @if(category.labeltext === 'Shift' || category.controlname === 'info-shift') {
    
            <div class="col">
    
                <div class="qn-question">{{category.labeltext}}</div>
    
                <div id="answer-3-shift-{{$index}}" class="qn-answer">{{category.response}}</div>
    
            </div>
    
            }
    
            @if(category.labeltext === "Crew" || category.controlname === 'info-crew') {
    
            <div class="col">
    
                <div class="qn-question">{{category.labeltext}}</div>
    
                <div id="answer-3-crew-{{$index}}" class="qn-answer">{{category.response}}</div>
    
            </div>
    
            }
    
            }
    
        </div>
    
        <div class="row">
            <div class="col-4">
    @if((selectedPlantCode === 'BUR2' && (positionCode === 'PKGCMPN' || positionCode === 'PSA')) || (selectedPlantCode === 'BUR1' && positionCode === 'PSA')){
        <div class="qn-question">{{ "app.previous_hoff.supervisor" | translate }}</div>
    }@else{
                <div class="qn-question">{{ "app.previous_hoff.operator" | translate }}</div>
    }
    
                <div id="answer-3-information-createdBy" class="qn-answer">{{createdBy}}</div>
    
            </div>
    
            @for(category of informationData; track $index;) {
    
            @if(category.labeltext === 'Supervisor' || category.controlname === 'info-shiftmanager') {
    
            <div class="col-4">
    
                <div class="qn-question">{{category.labeltext}}</div>
    
                <div id="answer-3-information-info-shiftmanager-supervisor" class="qn-answer">{{category.response}}</div>
    
            </div>
    
            }
    
            }
        </div>
    </div>
    }
    @if(isSupervisorPosition || (selectedPlantCode === 'BUR2' && positionCode === 'PKGCMPN')) {
        @if(selectedPlantCode=='BUR' && positionCode == 'PLS' ){
            <div class="row">
                <div class="col safety-section-header m-2">
                    <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
                    <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
                    <div class="col-md-2 ">
                        <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex">{{reportedDefectSafetyCount}}</div>
                    </div>
                </div>
                <div class="col quality-section-header m-2">
                    <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
                    <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
                    <div class="col-md-2 ">
                        <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex">{{reportedDefectQualityCount}}</div>
                    </div>
                </div>
            
            </div>
            <div class="row">
                <div class="col delivery-header m-2">
                    <div class="col-md-auto"><span id="d1-i-1" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                    <div class="col-md-8"> <span id="d1-h-1" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
                  
                </div>
                <div class="col env-header  m-2 hide">
                </div>
            
            </div>
        }
        @else{
            <div class="row">
                <div class="col safety-section-header m-2">
                    <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
                    <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
                    <div class="col-md-2 ">
                        <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex">{{reportedDefectSafetyCount}}</div>
                    </div>
                </div>
                <div class="col quality-section-header m-2">
                    <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
                    <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
                    <div class="col-md-2 ">
                        <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex">{{reportedDefectQualityCount}}</div>
                    </div>
                </div>
            
            </div>
            <div class="row">
                <div class="col delivery-header m-2">
                    <div class="col-md-auto"><span id="d-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                    <div class="col-md-8"> <span id="d-h" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
                  
                </div>
                <div class="col cost-header m-2">
                    <div class="col-md-auto"><span id="cost-icon" class="icon-sec cost-bg category-sec">C</span></div>
                    <div class="col-md-8"> <span id="cost-head" class="heading-span">{{ "app.previous_hoff.cost" | translate }}</span></div>
                </div>
            
            </div>
            <div class="row">
            
                <div class="col people-header m-2">
                    <div class="col-md-auto"><span id="p-i" class="icon-sec people-bg category-sec">P</span></div>
                    <div class="col-md-8"> <span id="p-h" class="heading-span">{{ "app.previous_hoff.people" | translate }}</span></div>
                  
                </div>
                <div class="col env-header  m-2">
                    <div class="col-md-auto"><span id="env-i" class="icon-sec env-bg category-sec">E</span></div>
                    <div class="col-md-8"> <span id="env-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span></div>
                    
                </div>
            
            </div>
        }
    
}
@else if(isOperatorPosition && selectedPlantCode!=='MAT' &&  (positionCode.includes('CDR') || positionCode.includes('PKG') || positionCode =='PL') ) {
    <div class="row">
        <div class="col safety-section-header m-2">
            <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
            <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
            <div class="col-md-2 ">
                <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex font-bold">
                    {{reportedDefectSafetyCount}}
                 </div>
            </div>
        </div>
        <div class="col quality-section-header m-2">
            <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
            <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
            <div class="col-md-2 ">
                <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex font-bold">
                    {{reportedDefectQualityCount}}
                </div>
            </div>
        </div>
    
       
        
    </div>
    @if((selectedPlantCode=='CDL') && (positionCode.includes('CDR') || (positionCode.includes('PL')))){}
    @else{
    <div class="row">
        
        <div class="col delivery-header m-2">
            <div class="col-md-auto"><span id="sc-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
            <div class="col-md-8"> <span id="sc-h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                    }}</span></div>
            <div class="col-md-2 ">
                <div id="sc-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                    {{getSummaryCountCLD()}}
                </div>
            </div>
        </div>
        <div class="col delivery-header m-2">
            <div class="col-md-auto"><span id="d-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
            <div class="col-md-8"> <span id="d-h" class="heading-span">{{ "app.previous_hoff.cid" | translate }}</span></div>
            <div class="col-md-2 ">
                <div id="d-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                    <i class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
                </div>
            </div>
        </div>
        
    
    
    </div>
    }
    @if((selectedPlantCode =='FVL' || selectedPlantCode=='CDL') && (positionCode.includes('CDR') || positionCode.includes('PKG') || positionCode.includes('CR') || positionCode =='PL')){
    }
    @else{
        <div class="row">
        <div class="col delivery-header m-2">
            <div class="col-md-auto"><span id="es-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
            <div class="col-md-8"> <span id="es-h" class="heading-span">{{ "app.previous_hoff.equipment_stops" | translate }}</span>
            </div>
            <div class="col-md-2 ">
                <div id="es-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                      {{getSummaryCount("EQUIPMENT STOPS")}}
                </div>
            </div>
        </div>
        <div class="col delivery-header m-2">
            <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
            <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
            </div>
            <div class="col-md-2 ">
                <div id="ed-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
                </div>
            </div>
        </div>
    </div>
    }
    @if(isOperatorPosition && ( positionCode === 'CDR' || positionCode === 'PL' ) &&  selectedPlantCode =='CDL' || selectedPlantCode =='BUR'){
       @if(!positionCode.includes('PKG') ){
        <div class="row">
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sc-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sc-h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                        }}</span></div>
                <div class="col-md-2 ">
                    <div id="sc-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        {{getSummaryCountCLD()}}
                    </div>
                </div>
            </div>
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="d8i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="d8h" class="heading-span">{{ "app.previous_hoff.cid" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="d8c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        <i class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
                    </div>
                </div>
            </div>
            
        
        
        </div>
            }
        } 
    
        @if((isOperatorPosition && ( positionCode === 'CDR') &&  selectedPlantCode =='CDL' && positionCode!='CDR')){
           
            <div class="row">
            <div class="col env-header m-2">
                <div class="col-md-auto"><span id="dv-i" class="icon-sec env-bg category-sec">E</span></div>
                <div class="col-md-8"> <span id="dv-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="dv-c" class="defect-sec  env-bg justify-content-center align-items-center d-flex font-bold">
                        {{reportedDefectENVCount}} 
                    </div>
                </div>
            </div>
            <div class="col m-2">
                
            </div>
        </div>
        }
    } 
    @else if(isOperatorPosition && (positionCode === 'CR' &&  selectedPlantCode !='MAT'))
    {
    
        <div class="row">
            <div class="col safety-section-header m-2">
                <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
                <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex font-bold">
                        {{reportedDefectSafetyCount}}
                    </div>
                </div>
            </div>
            
             
            <div class="col quality-section-header m-2">
                <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
                <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex font-bold">
                        {{reportedDefectQualityCount}}
                    </div>
                </div>
            </div>
          
        
        </div>
      
        <div class="row">
        
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sc-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sc-h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                        }}</span></div>
                <div class="col-md-2 ">
                    <div id="sc-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                         {{getSummaryCountCLD()}}
                    </div>
                </div>
            </div>
            <div class="col delivery-header  m-2">
                <div class="col-md-auto"><span id="es-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="es-h" class="heading-span">{{ "app.previous_hoff.equipment_stops" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="es-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{getSummaryCount("EQUIPMENT STOPS")}}
                    </div>
                </div>
            </div>
        
        </div>
        @if(selectedPlantCode =='FVL')
        {
        <div class="row equipment_defects-width">
            <div class="col  delivery-header   m-2">
                <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="ed-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
                    </div>
                </div>
            </div>
        </div>   
    }
    @else if(selectedPlantCode =='CDL')
    {
        <div class="row">
            <div class="col  delivery-header   m-2">
                <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="ed-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
                    </div>
                </div>
            </div>
            <div class="col env-header m-2">
                <div class="col-md-auto"><span id="dv-i" class="icon-sec env-bg category-sec">E</span></div>
                <div class="col-md-8"> <span id="dv-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="dv-c" class="defect-sec  env-bg justify-content-center align-items-center d-flex font-bold">
                        {{reportedDefectENVCount}} 
                    </div>
                </div>
            </div>
            
        </div>
    } 
    
    }
    @else if(isOperatorPosition && (positionCode === 'MR' && selectedPlantCode!=='MAT') || (selectedPlantCode == 'BUR' && positionCode.includes('BTR')) ){
        <div class="row">
            <div class="col safety-section-header m-2">
                <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
                <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex font-bold">
                        {{reportedDefectSafetyCount}}
                     </div>
                </div>
            </div>
                <div class="col quality-section-header m-2">
                <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
                <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex font-bold">
                         {{reportedDefectQualityCount}}
                    </div>
                </div>
            </div>
           
            
        </div>
        <div class="row">
   
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sc-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sc-h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                        }}</span></div>
                <div class="col-md-2 ">
                    <div id="sc-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        {{getSummaryCountCLD()}}
                    </div>
                </div>
            </div>
            @if(selectedPlantCode == 'BUR' && positionCode.includes('BTR')){
                <div class="col delivery-header m-2">
                    <div class="col-md-auto"><span id="sum-i-d" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                    <div class="col-md-8"> <span id="sum-h-d" class="heading-span">{{ "app.previous_hoff.cid" | translate }}</span></div>
                    <div class="col-md-2 ">
                        <div id="sum-c-d" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                            <i class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
                        </div>
                    </div>
                </div>
                
                }@else{
                    <div class="col other-issue-section-header m-2">
                        <div id="d14-is" class="col-md-8"> <span class="heading-span previous-information-section">{{
                                "app.previous_hoff.other_issues" | translate }}</span></div>
                    </div>
                }
        
        </div>
        @if(selectedPlantCode=='FVL' && positionCode=='MR' ){}
        @else{
        <div class="row">
        
          
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="es-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="es-h" class="heading-span">{{ "app.previous_hoff.equipment_stops" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="es-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        {{getSummaryCount("EQUIPMENT STOPS")}}  
                    </div>
                </div>
            </div>
            <div class="col delivery-header  m-2">
                <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
                </div>
                <div id="ed-c" class="col-md-2 ">
                    <div class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
                    </div>
                </div>
            </div>
        
        </div>
        }
    }
    @else if(isOperatorPosition && (positionCode === 'PSA' || positionCode === 'FORK' || positionCode === 'PKGCMPN')){
        <div class="row">
            <div class="col safety-section-header m-2">
                <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
                <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex">{{reportedDefectSafetyCount}}</div>
                </div>
            </div>
            <div class="col quality-section-header m-2">
                <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
                <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex">{{reportedDefectQualityCount}}</div>
                </div>
            </div>
        
        </div>
        <div class="row">
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sum-i-d" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sum-h-d" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
              
            </div>
            <div class="col cost-header m-2">
                <div class="col-md-auto"><span id="sum-i-c" class="icon-sec cost-bg category-sec">C</span></div>
                <div class="col-md-8"> <span id="sum-h-c" class="heading-span">{{ "app.previous_hoff.cost" | translate }}</span></div>
            </div>
        
        </div>
        <div class="row">
            <div class="col people-header m-2">
                <div class="col-md-auto"><span id="p-i" class="icon-sec people-bg category-sec">P</span></div>
                <div class="col-md-8"> <span id="p-h" class="heading-span">{{ "app.previous_hoff.people" | translate }}</span></div>
              
            </div>
            <div class="col env-header  m-2">
                <div class="col-md-auto"><span id="env-i" class="icon-sec env-bg category-sec">E</span></div>
                <div class="col-md-8"> <span id="env-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span></div>
                @if(selectedPlantCode=='BUR' && positionCode === 'FORK'){
                    <div class="col-md-2 ">
                        <div id="dv-c" class="defect-sec  env-bg justify-content-center align-items-center d-flex font-bold">
                            {{reportedDefectENVCount}} 
                        </div>
                    </div>
                }
            </div>
        
        </div>
    }
    @else if(isOperatorPosition && positionCode === 'ATSPL' && selectedPlantCode=='BUR' ){
        <div class="row">
            <div class="col safety-section-header m-2">
                <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
                <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex">{{reportedDefectSafetyCount}}</div>
                </div>
            </div>
            <div class="col quality-section-header m-2">
                <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
                <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
                <div class="col-md-2 ">
                    <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex">{{reportedDefectQualityCount}}</div>
                </div>
            </div>
        
        </div>
        <div class="row">
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sum-i-d" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sum-h-d" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
            </div>
            <div class="col env-header  m-2">
                <div class="col-md-auto"><span id="sum-i-e" class="icon-sec env-bg category-sec">E</span></div>
                <div class="col-md-8"> <span id="sum-i-e" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span></div>
            </div>
        </div>
    } 
    @else{
 
    <div class="row">
        <div class="col safety-section-header m-2">
            <div class="col-md-auto"><span id="s-icon-1" class="icon-sec safety-bg category-sec">S</span></div>
            <div class="col-md-8"> <span id="s-heading-1" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
            <div class="col-md-2 ">
                <div id="s-count-1" class="defect-sec safety-bg  justify-content-center align-items-center d-flex font-bold">
                    {{reportedDefectSafetyCount}}
                 </div>
            </div>
        </div>
        <div class="col quality-section-header m-2">
            <div class="col-md-auto"><span id="q-icon-1" class="icon-sec quality-bg category-sec">Q</span></div>
            <div class="col-md-8"> <span id="q-heading-1" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
            <div class="col-md-2 ">
                <div id="q-count-1" class="defect-sec quality-bg justify-content-center align-items-center d-flex font-bold">
                   {{reportedDefectQualityCount}}
                </div>
            </div>
        </div>
    
       
        
    </div>
    @if(selectedPlantCode ==='MAT' &&  (positionCode ==='QC' || positionCode=='CR' || positionCode=='CC' || positionCode=='AFTR' || positionCode=='NTYR' )){
        <div class="row">
            <div class="col delivery-header m-2">
         <div class="col-md-auto"><span id="sc-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
         <div class="col-md-8"> <span id="sc-h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                 }}</span></div>
         <div class="col-md-2 ">
             <div id="sc-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                 {{getSummaryCountCLD()}}
             </div>
         </div>
     </div>
             <div class="col delivery-header m-2">
         <div class="col-md-auto"><span id="es-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
         <div class="col-md-8"> <span id="es-h" class="heading-span">{{ "app.previous_hoff.equipment_stops" | translate }}</span>
         </div>
         <div class="col-md-2 ">
             <div id="es-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                 {{getSummaryCount("EQUIPMENT STOPS")}}
             </div>
         </div>
     </div>
     </div>
     <div class="row">
        <div class="col delivery-header m-2">
     <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
     <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
     </div>
     <div class="col-md-2 ">
         <div id="ed-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
         </div>
     </div>
 </div>
     <div class="col env-header m-2">
         <div class="col-md-auto"><span id="dv-i" class="icon-sec env-bg category-sec">E</span></div>
         <div class="col-md-8"> <span id="dv-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span>
         </div>
         <div class="col-md-2 ">
             <div id="dv-c" class="defect-sec  env-bg justify-content-center align-items-center d-flex font-bold">
                 {{reportedDefectENVCount}} 
             </div>
         </div>
     </div>
 </div>
    }
    @else if(selectedPlantCode ==='MAT' &&  ( positionCode ==='BMN' || positionCode=='CDR' || positionCode=='CM' || positionCode=='MR')){
        @if(selectedPlantCode === 'MAT' && positionCode === 'CM'){
            <div class="row">
                <div class="col delivery-header m-2">
                    <div class="col-md-auto"><span id="d22i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                    <div class="col-md-8"> <span id="d22h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                            }}</span></div>
                    <div class="col-md-2 ">
                        <div id="d22c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                            {{getSummaryCountCLD()}}
                        </div>
                    </div>
                </div>
                <div class="col delivery-header m-2">
                    <div class="col-md-auto"><span id="d24i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                    <div class="col-md-8"> <span id="d24h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
                    </div>
                    <div class="col-md-2 ">
                        <div id="d24c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col env-header m-2">
                    <div class="col-md-auto"><span id="e7i" class="icon-sec env-bg category-sec">E</span></div>
                    <div class="col-md-8"> <span id="e7h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span>
                    </div>
                    <div class="col-md-2 ">
                        <div id="e7c" class="defect-sec  env-bg justify-content-center align-items-center d-flex font-bold">
                            {{reportedDefectENVCount}} 
                        </div>
                    </div>
                </div>
                <div class="col m-2"></div>

            </div>
        }
        @else{
        <div class="row">
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sc-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sc-h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                        }}</span></div>
                <div class="col-md-2 ">
                    <div id="sc-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        {{getSummaryCountCLD()}}
                    </div>
                </div>
            </div>
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sum-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sum-h" class="heading-span">{{ "app.previous_hoff.cid" | translate }}</span></div>
              
                <div class="col-md-2 ">
                    <div id="sum-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        <i class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
           <div class="col delivery-header m-2">
        <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
        <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
        </div>
        <div class="col-md-2 ">
            <div id="ed-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
            </div>
        </div>
    </div>
        <div class="col env-header m-2">
            <div class="col-md-auto"><span id="dv-i" class="icon-sec env-bg category-sec">E</span></div>
            <div class="col-md-8"> <span id="dv-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span>
            </div>
            <div class="col-md-2 ">
                <div id="dv-c" class="defect-sec  env-bg justify-content-center align-items-center d-flex font-bold">
                    {{reportedDefectENVCount}} 
                </div>
            </div>
        </div>
    </div>
    }
       }
       @else if(!(selectedPlantCode ==='BUR' && positionCode =='PCMPN')){
        <div class="row">
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sc-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sc-h" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate
                        }}</span></div>
                <div class="col-md-2 ">
                    <div id="sc-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        {{getSummaryCountCLD()}}
                    </div>
                </div>
            </div>
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="sum-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="sum-h" class="heading-span">{{ "app.previous_hoff.cid" | translate }}</span></div>
              
                <div class="col-md-2 ">
                    <div id="sum-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        <i class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
                    </div>
                </div>
            </div>
            
        
        
        </div>
    }
    }    
    @if(isOperatorPosition && selectedPlantCode!=='BUR' &&  positionCode!=='PCMPN' && selectedPlantCode!=='BUR2'
  ){

        @if(isOperatorPosition && selectedPlantCode ==='MAT' &&  (positionCode ==='QC' || positionCode=='CR' || positionCode=='CC' || positionCode=='AFTR' || positionCode=='NTYR' 
        || positionCode ==='BMN' || positionCode=='CDR' || positionCode=='CM' || positionCode=='MR')){
        }
        @else if(isOperatorPosition && (selectedPlantCode ==='FVL' || selectedPlantCode ==='CDL') &&  (positionCode ==='CR')){}
        @else if(selectedPlantCode === 'BUR1' && positionCode === 'PSA'){}
        @else{
            <div class="row" >
                    <div class="col delivery-header m-2">
                        <div class="col-md-auto"><span id="es-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                        <div class="col-md-8"> <span id="es-h" class="heading-span">{{ "app.previous_hoff.equipment_stops" | translate }}</span>
                        </div>
                        <div class="col-md-2 ">
                            <div id="es-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                                {{getSummaryCount("EQUIPMENT STOPS")}}
                            </div>
                        </div>
                    </div>
                    <div class="col delivery-header m-2">
                        <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                        <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
                        </div>
                        <div class="col-md-2 ">
                            <div id="ed-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
                            </div>
                        </div>
                    </div>
                </div>
    }
   }
    @if(isOperatorPosition && selectedPlantCode =='BUR' &&  positionCode =='PCMPN'){
    <div class="row">
        <div class="col delivery-header m-2">
            <div class="col-md-auto"><span id="sum-i-d" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
            <div class="col-md-8"> <span id="sum-h-d" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
          
        </div>
        <div class="col cost-header m-2">
            <div class="col-md-auto"><span id="cost-icon" class="icon-sec cost-bg category-sec">C</span></div>
            <div class="col-md-8"> <span id="cost-head" class="heading-span">{{ "app.previous_hoff.cost" | translate }}</span></div>
        </div>
    
    </div>
    <div class="row">
    
        <div class="col people-header m-2">
            <div class="col-md-auto"><span id="p-i" class="icon-sec people-bg category-sec">P</span></div>
            <div class="col-md-8"> <span id="p-h" class="heading-span">{{ "app.previous_hoff.people" | translate }}</span></div>
          
        </div>
        <div class="col env-header  m-2">
            <div class="col-md-auto"><span id="env-i" class="icon-sec env-bg category-sec">E</span></div>
            <div class="col-md-8"> <span id="env-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span></div>
            
        </div>
    
    </div>
    }

    @if(isOperatorPosition && selectedPlantCode =='BUR' && (positionCode =='STR' || positionCode =='CTR' || positionCode=='FROP' || positionCode=='PLR')){
        <div class="row">
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="es-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="es-h" class="heading-span">{{ "app.previous_hoff.equipment_stops" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="es-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">
                        {{getSummaryCount("EQUIPMENT STOPS")}}
                    </div>
                </div>
            </div>
            <div class="col delivery-header m-2">
                <div class="col-md-auto"><span id="ed-i" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
                <div class="col-md-8"> <span id="ed-h" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="ed-c" class="defect-sec color-text delivery-bg justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}
                    </div>
                </div>
            </div>
        </div>
    } 
    
    @if((isOperatorPosition && (positionCode.includes('PKG') || positionCode=='PL' || positionCode === 'SPLMIX' || positionCode== 'PSL' || positionCode === 'PH' ||positionCode === 'FRY' || positionCode === 'SPLFRY' || positionCode === 'SADR' || positionCode === 'BTR' || positionCode === 'CT' || positionCode=='CDR') &&  selectedPlantCode =='CDL') || (selectedPlantCode == 'MAT' && (positionCode === 'CT' || positionCode === 'PH'|| positionCode === 'FRY' || positionCode === 'PKG1' 
    || positionCode=='PKG3' || positionCode === 'SPDT' || positionCode=='CF'|| positionCode=='PKG5' || positionCode=='EL' || positionCode=='SADR'|| positionCode=='PL' ) || ( selectedPlantCode == 'BUR' && (positionCode=='CTR' || positionCode=='FROP' || positionCode=='STR' || positionCode=='PLR'  ))||(selectedPlantCode == 'BUR' && (positionCode.includes('PKG') || positionCode.includes('BTR'))))){
        <div class="row">
            <div class="col env-header m-2">
                <div class="col-md-auto"><span id="dv-i" class="icon-sec env-bg category-sec">E</span></div>
                <div class="col-md-8"> <span id="dv-h" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span>
                </div>
                <div class="col-md-2 ">
                    <div id="dv-c" class="defect-sec  env-bg justify-content-center align-items-center d-flex font-bold">
                        {{reportedDefectENVCount}} 
                    </div>
                </div>
            </div>
            <div class="col m-2">
                 
            </div>
        
        </div>
    }
}