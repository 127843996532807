import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { RtDuete } from '../../../../../../../../../../core/models/rtduete';

@Component({
  selector: 'app-equipment-stops',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './equipment-stops.component.html',
  styleUrl: './equipment-stops.component.scss'
})
export class EquipmentStopsComponent {
  @Input() selectedPlantCode: any;
  @Input() positionCode: any;
  @Input() equStopsResponse: any;
  @Input() reportedRTDuetDefectObj: any;
  @Input() equipmentStopsControls: any;
  public RTDuetequipmentStops: any=[];
  public groupedByArea: any = []; 
  public selectedLang: string;
  public rtDueteResponse : any[]=[];

  constructor(private translateService: TranslateService){
    if(sessionStorage.getItem('selectedLanguage') !== ""){
      this.selectedLang = String(sessionStorage.getItem('selectedLanguage'));
    }
  }
  ngOnChanges() {
    this.getEquipmentStopsResponse();
    if (this.rtDueteResponse && this.rtDueteResponse?.length>0) {
      this.RTDuetequipmentStops = this.rtDueteResponse;
      this.RTDuetequipmentStops.forEach((element: any, index: any) => {
        element.controls = [];
        let nextStep = '';
        let nextStepindex = this.equipmentStopsControls.findIndex((x: { controlname: string; }) => x.controlname == 'suggestedSteps' + index);
        if (nextStepindex >= 0) {
          nextStep = this.equipmentStopsControls[nextStepindex].response;
        }
        let flag = '';
        let flagindex = this.equipmentStopsControls.findIndex((x: { controlname: string; }) => x.controlname == 'suggestedStepsflg' + index);
        if (flagindex >= 0) {
          flag = this.equipmentStopsControls[flagindex].response;
        }
        element.controls.push({ controlname: 'suggestedSteps' + index, response: nextStep });
        element.controls.push({ controlname: 'suggestedStepsflg' + index, ctrltype: 'flagicon', response: flag });
      });
      this.getFirstIndexOfUniqueItems(this.RTDuetequipmentStops);
    }

  }
  getEquipmentStopsResponse(){
    if(this.rtDueteResponse.length === 0 ){
      let count = 0;
      for (let i = 0; i < this.equipmentStopsControls?.length; i++) {
        const currentControl = this.equipmentStopsControls[i];
        if(currentControl?.controlname?.includes('suggestedStepsflg') && this.equipmentStopsControls[i].areaname != 'PACKAGING LINE'){
          count++;
         const currentRtDuete = {
            area: "",
            downtimeCategory: "",
            duration: "",
            line: "",
            lineOffTime: "",
            lineOnTime: "",
            operatorComments: "",
            rootCause: "",
            controls: []
          };
          this.rtDueteResponse.push(currentRtDuete);
        }
      }
      for(let i =0;i<count;i++){
        const currentControl = this.equipmentStopsControls[i];
        for(let j = 0;j<this.equipmentStopsControls.length; j++){
          if(this.equipmentStopsControls[j].areaname != 'PACKAGING LINE'){
            if(this.equipmentStopsControls[j].controlname === `area-ctrl-${i}`){
              this.rtDueteResponse[i].area = this.equipmentStopsControls[j].response
            }
            if(this.equipmentStopsControls[j].controlname === `downtime-ctrl-${i}`){
              this.rtDueteResponse[i].duration = this.equipmentStopsControls[j].response
            }
            if(this.equipmentStopsControls[j].controlname === `category-ctrl-${i}`){
              this.rtDueteResponse[i].downtimeCategory = this.equipmentStopsControls[j].response
            }
            if(this.equipmentStopsControls[j].controlname === `lineoff-ctrl-${i}`){
              this.rtDueteResponse[i].lineOffTime = new Date(this.equipmentStopsControls[j].response).getTime().toString()
            }
            if(this.equipmentStopsControls[j].controlname === `lineon-ctrl-${i}`){
              this.rtDueteResponse[i].lineOnTime = new Date(this.equipmentStopsControls[j].response).getTime().toString()
            }
            if(this.equipmentStopsControls[j].controlname === `opcmnt-ctrl-${i}`){
              this.rtDueteResponse[i].operatorComments = this.equipmentStopsControls[j].response
            }
            if(this.equipmentStopsControls[j].controlname === `suggestedSteps${i}`){
              this.rtDueteResponse[i].controls.push({
                  controlname: currentControl.controlname,
                  response: currentControl.response
              });
            }
            if(this.equipmentStopsControls[j].controlname === `suggestedStepsflg${i}`){
              this.rtDueteResponse[i].controls.push({
                controlname: currentControl.controlname,
                ctrltype: 'flagicon',
                response: currentControl.response
              });
            }
          }
          
        }
    }
    }
    
}
   


  getFirstIndexOfUniqueItems(items: any[]): Record<number, number> {
    const uniqueIndexes: Record<number, number> = {};

    items.forEach((item, index) => {
      if (uniqueIndexes[item.area] === undefined) {
        uniqueIndexes[item.area] = index;
        this.groupedByArea.push(index);
      }
    });

    return uniqueIndexes;
  }

  getDateFromEpoch(epochtime: any) {
    if (epochtime) {
      if(this.selectedLang === 'fr'){
        return moment(parseInt(epochtime)).format('MM/DD/YYYY HH:mm');
      }else{
        return moment(parseInt(epochtime)).format('MM/DD/YYYY hh:mm:ss A');
      }
    }
    else {
      return '';
    }
  }
  
}
