import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';


@Component({
  selector: 'app-reported-defect-view',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './reported-defect-ui.component.html',
  styleUrl: './reported-defect-ui.component.scss'
})
export class ReportedDefectUiComponent  {
  @Input() responseData: any;
  @Input() reportedCategoryDefectArray: any;
  @Input() selectedPlantCode: any;
  @Input() tableId: string;
  
  public selectedLang: string;

  constructor(){
    if(sessionStorage.getItem('selectedLanguage') !== ""){
      this.selectedLang = String(sessionStorage.getItem('selectedLanguage'));
    }
  }

  getDateFromEpoch(epochtime: any) {
    if (epochtime) {
       if(this.selectedLang === 'fr'){
        return moment(parseInt(epochtime)).format('DD/MM/YYYY HH:mm');
      }else{
        return moment(parseInt(epochtime)).format('MM/DD/YYYY hh:mm A');
      }
    }
    else {
      return '';
    }

  }
}