
<nav class="nav nav-pills flex-column flex-sm-row">
    @for(selectedPosition of positionNames; track selectedPosition; let i = $index) {
    <a class="flex-sm-fill text-sm-center nav-link p-3" (click)="getPositionSelected(selectedPosition)"
        [ngClass]="{active:$first==true}" aria-current="page" data-bs-toggle="tab" role="tab"
        [id]="getFormattedPositionId(selectedPosition)">
        {{selectedPosition}}
    </a>
    }
</nav>
<ng-container *ngIf="isHandoffEnabled;" >
    @if(previousHandOff) {
    <div class="card  mb-1">
        <div class="card-body py-2 ps-0">
            <div class="row">
                <div class="col acc-container align-content-center justify-content-center">
                    <div class="p-2">
                        <h6> {{ "app.previous_handoff" | translate }} 
                        ({{(toggleSummaryDetailView) ? ("app.previous_hoff.detailed" | translate):  ("app.previous_hoff.summary" | translate) }}): <span>{{posName}}</span> </h6>

                    </div>
                </div>

                <div class="col d-flex justify-content-end">
                    <button [id]="toggleSummaryDetailView ? 'view-summary-button' : 'view-detailed-button'" type="button" class="btn btn-secondary px-4 border-2 shift-btn-style"
                        (click)="toggleView()">{{(toggleSummaryDetailView) ? (("app.previous_hoff.view_summary"  | translate)) : (("app.previous_hoff.view_detailed" | translate)) }}</button>

                </div>


            </div>
        </div>
    </div>
    } @else if(handOffType === 'current'){
    <div class="card  mb-1">
        <div class="card-body py-2 ps-0">
            <div class="row">
                <div class="col acc-container align-content-center justify-content-center">
                    <div class="p-2">
                        <h6>{{ "app.current_handoff" | translate }} : <span>{{posName}}</span></h6>

                    </div>


                </div>

                <div class="col d-flex p-0 justify-content-end">
                    <div class="ps-2"><button [id]="enableSaveTrue ? 'save-button' : 'saved-button'" type="button"
                            [ngClass]="enableSaveTrue ? ' btn btn-secondary px-4 border-2 submit-btn-enbl-style' :'btn btn-secondary px-4 border-2 submit-btn-style'"
                            [disabled]="!enableSaveTrue" (click)="submit(true)">{{ (enableSaveTrue) ? ("app.current_hoff.save" | translate | uppercase) : ("app.current_hoff.saved" | translate | uppercase) }}
                        </button></div>
                    <div class="ps-2"><button type="button"
                        [id]="isDisableGlobally ? 'submitted-button' : enableSubmitTrue ? 'submit-button' : 'submit-disabled-button'" 
                            [ngClass]="isDisableGlobally ? 'btn btn-secondary px-4 border-2 submit-btn-style' : enableSubmitTrue ? ' btn btn-secondary px-4 border-2 submit-btn-enbl-style' :'btn btn-secondary px-4 border-2 submit-btn-style'"
                            [disabled]="isDisableGlobally?true:!enableSubmitTrue" (click)="submit(false)">{{isDisableGlobally ? ("app.current_hoff.submitted" | translate | uppercase) : ("app.current_hoff.submit" | translate | uppercase)}}</button></div>
                            
                </div>



            </div>
        </div>
    </div>
    }
</ng-container>

@if(isLoading) {
    <div class="overlay z-1 d-flex align-items-center justify-content-center">
        <div class="overlay__inner">
            <div class="overlay__content">
                <span class="spinner"></span>
                <p>{{ "app.current_hoff.loading" | translate }}...</p>
            </div>
        </div>
    </div>
    }