@if(reportedCategoryDefectArray && reportedCategoryDefectArray.length > 0)
{
@for (safetyDefect of reportedCategoryDefectArray; track safetyDefect; let k = $index) {
<div class="defect-repoted-table-flex">
  @if(selectedPlantCode!=='MAT' && selectedPlantCode!=='HAR'){
  <table id="{{tableId}}-table-{{k + 1}}" class="table report-defect-table">
    <tbody>
      <tr class="report-defect-tr">
        <td class="report-defect-td">{{ "app.previous_hoff.report_defect" | translate }}</td>
        <td colspan="2" class="defect-value">{{safetyDefect["defectCode"]}}</td>
      </tr>
      <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
        <td class="report-defect-td">{{ "app.previous_hoff.area" | translate }}</td>
        <td colspan="2" class="defect-value">{{safetyDefect["area"]?.name}}</td>
      </tr>
      <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
        <td class="report-defect-td">{{ "app.previous_hoff.equipment" | translate }}</td>
        <td colspan="2" class="defect-value">{{safetyDefect["equipment"]?.name}}</td>
      </tr>
      <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
        <td class="report-defect-td">{{ "app.previous_hoff.identification" | translate }}</td>
        <td colspan="2" class="defect-value">{{safetyDefect["identification"]?.name}}</td>
      </tr>
      <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
        <td class="report-defect-td">{{ "app.previous_hoff.impact" | translate }}</td>
        <td colspan="2" class="defect-value">{{safetyDefect["impact"]?.name}}</td>
      </tr>
      <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
        <td class="report-defect-td">{{ "app.previous_hoff.title" | translate }}</td>
        <td colspan="2" class="defect-value">{{safetyDefect["title"]}}</td>
      </tr>
      <tr>
        <td class="report-defect-td">{{ "app.previous_hoff.description" | translate }}</td>
        <td colspan="2" class="defect-value">{{safetyDefect["description"]}}</td>
      </tr>
      <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
        <td class="report-defect-td">{{ "app.previous_hoff.next_step" | translate }}</td>
        <td colspan="2">{{safetyDefect["nextSteps"]?.name}}</td>
      </tr>
      <tr *ngIf="(safetyDefect['impact']?.code == 'QLT' || safetyDefect['impact']?.code == 'SFT')">
        <td class="report-defect-td">{{ "app.previous_hoff.sup_notified" | translate }}</td>
        <td colspan="2">{{ "app.previous_hoff.no" | translate }}</td>
      </tr>
      <tr>
        <td class="report-defect-td">{{ "app.previous_hoff.reported_by" | translate }}</td>
        <td class="text-capitalize">{{safetyDefect["reportedBy"]?.["name"]}}</td>
        <td>
          <h4 class="defect-date-table ">{{getDateFromEpoch(safetyDefect["createdDateTime"])}}
          </h4>
        </td>
      </tr>
      <tr>
        <td class="report-defect-td">{{ "app.previous_hoff.attachments" | translate }}</td>
        <td colspan="2">{{ "app.previous_hoff.view" | translate }}</td>
      </tr>
    </tbody>
  </table>
}
  @else{
    <table id="{{tableId}}-table-{{k + 1}}" class="table report-defect-table">
      <tbody>
        <tr class="report-defect-tr">
          <td class="report-defect-td">{{ "app.previous_hoff.report_defect" | translate }}</td>
          <td colspan="2" class="defect-value">{{safetyDefect["defectCode"]}}</td>
        </tr>
        <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
          <td class="report-defect-td">{{ "app.previous_hoff.area" | translate }}</td>
          <td colspan="2" class="defect-value">{{safetyDefect["area"]?.name}}</td>
        </tr>
        <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
          <td class="report-defect-td">{{ "app.previous_hoff.equipment" | translate }}</td>
          <td colspan="2" class="defect-value">{{safetyDefect["equipment"]?.name}}</td>
        </tr>
        <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
          <td class="report-defect-td">{{ "app.previous_hoff.identification" | translate }}</td>
          <td colspan="2" class="defect-value">{{safetyDefect["identification"]?.name}}</td>
        </tr>
        <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
          <td class="report-defect-td">{{ "app.previous_hoff.impact" | translate }}</td>
          <td colspan="2" class="defect-value">{{safetyDefect["impact"]?.name}}</td>
        </tr>
        <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
          <td class="report-defect-td">{{ "app.previous_hoff.title" | translate }}</td>
          <td colspan="2" class="defect-value">{{safetyDefect["title"]}}</td>
        </tr>
        <tr>
          <td class="report-defect-td">{{ "app.previous_hoff.description" | translate }}</td>
          <td colspan="2" class="defect-value">{{safetyDefect["description"]}}</td>
        </tr>
        <tr *ngIf="(safetyDefect['impact']?.code === 'QLT' || safetyDefect['impact']?.code === 'SFT')">
          <td class="report-defect-td">{{ "app.current_hoff.sup_notified" | translate }}</td>
          <td colspan="2" class="defect-value">{{ "app.previous_hoff.no" | translate }}</td>
        </tr>
        <tr *ngIf="(safetyDefect['impact']?.code != 'QLT' && safetyDefect['impact']?.code != 'SFT')">
          <td class="report-defect-td">{{ "app.previous_hoff.next_step" | translate }}</td>
          <td colspan="2">{{safetyDefect["nextSteps"]?.name}}</td>
        </tr>
        <tr>
          <td class="report-defect-td">{{"app.current_hoff.reported_by" | translate }}, {{"app.current_hoff.dateDefect" | translate }}</td>
          <td class="text-capitalize">{{safetyDefect["reportedBy"]?.["name"]}}</td>
          <td>
            <h4 class="defect-date-table ">{{getDateFromEpoch(safetyDefect["createdDateTime"])}}
            </h4>
          </td>
        </tr>
        <tr>
          <td class="report-defect-td">{{ "app.previous_hoff.attachments" | translate }}</td>
          <td colspan="2">{{ "app.previous_hoff.view" | translate }}</td>
        </tr>
      </tbody>
    </table>
    }
  <div id="{{tableId}}-table-flag-{{k + 1}}"  *ngIf="!((selectedPlantCode == 'CDL' || selectedPlantCode == 'MAT'||selectedPlantCode == 'BUR' || selectedPlantCode == 'HAR' || selectedPlantCode == 'BUR2' || selectedPlantCode == 'BUR1') && safetyDefect['impact']?.code == 'ENV')"
    class="{{(safetyDefect['isFlagged'] == 'true' || safetyDefect['isFlagged'] == true) ? 'table-defect-flag-selected' :'table-defect-flag'}}">
  </div>
  <div id="{{tableId}}-table-emt-{{k + 1}}" *ngIf="(selectedPlantCode == 'CDL' || selectedPlantCode == 'MAT'|| selectedPlantCode == 'HAR' || selectedPlantCode == 'BUR2' || selectedPlantCode == 'BUR1') && safetyDefect['impact']?.code == 'ENV'"
    class="table-defect-emptyspace">
  </div>
   
</div>
}
}