@if(isSupervisorPosition) {
  <div id="info_sup" class="information-container">
    <div class="row">
      <div class="col m-2 border info-section-header">
        <span id="info-sup-head" class="heading-span previous-information-section">{{ "app.previous_hoff.information" | translate }}</span>
      </div>
    </div>
    <div class="row">
      @for(category of informationData; track $index;) {
      @if(category.labeltext === 'Date' || category.controlname === 'info-date') {
      <div class="col">
        <div class="qn-question">{{ "app.previous_hoff.date" | translate }} </div>
        <div class="qn-answer">{{formateDate(category.response)}}</div>
      </div>
      }
      @if(category.labeltext === 'Shift' || category.controlname === 'info-shift') {
      <div class="col">
        <div class="qn-question">{{ "app.previous_hoff.shift" | translate }}</div>
        <div class="qn-answer">{{category.response}}</div>
      </div>
      }
      @if(category.labeltext === "Crew" || category.controlname === 'info-crew') {
      <div class="col">
        <div class="qn-question">{{ "app.previous_hoff.crew" | translate }}</div>
        <div class="qn-answer">{{category.response}}</div>
      </div>
      }
  
      }
  
    </div>
  
    <div class="row">
      <div class="col-4">
        <div class="qn-question">{{ "app.previous_hoff.supervisor" | translate }}</div>
        <div class="qn-answer">{{createdBy}}</div>
      </div>
      @for(category of informationData; track $index;) {
  
      @if(category.labeltext === 'Shift Manager' || category.controlname === 'info-shiftmanager') {
      <div class="col-4">
        <div class="qn-question">{{ category.labeltext }}</div>
        <div class="qn-answer">{{category.response}}</div>
      </div>
      }
      }
  
    </div>
  </div>
  } @else if(isOperatorPosition) {
  <div id="info_op" class="information-container">
    <div class="row">
      <div class="col m-2 border info-section-header">
        <span id="info-op-head" class="heading-span">{{ "app.previous_hoff.information" | translate }}</span>
      </div>
    </div>
    <div class="row mb-3 ">
      @for(category of informationData; track $index;) {
      @if(category.labeltext === 'Date' || category.controlname === 'info-date') {
      <div class="col">
        <div class="qn-question">{{category.labeltext}} </div>
        <div class="qn-answer">{{formateDate(category.response)}}</div>
      </div>
      }
      @if(category.labeltext === 'Shift' || category.controlname === 'info-shift') {
      <div class="col">
        <div class="qn-question">{{category.labeltext}}</div>
        <div class="qn-answer">{{category.response}}</div>
      </div>
      }
      @if(category.labeltext === "Crew" || category.controlname === 'info-crew') {
      <div class="col">
        <div class="qn-question">{{category.labeltext}}</div>
        <div class="qn-answer">{{category.response}}</div>
      </div>
      }
  
      }
  
    </div>
  
    <div class="row">
      <div class="col-4">
        @if((selectedPlantCode === 'BUR2' && (positionCode === 'PKGCMPN' || positionCode === 'PSA')) || (selectedPlantCode === 'BUR1' && positionCode === 'PSA')){
          <div class="qn-question">{{ "app.previous_hoff.supervisor" | translate }}</div>
      }@else{
        <div class="qn-question">{{ "app.previous_hoff.operator" | translate }}</div>
      }
        <div class="qn-answer">{{createdBy}}</div>
      </div>
  
      @for(category of informationData; track $index;) {
  
      @if(category.labeltext === 'Supervisor' || category.controlname === 'info-shiftmanager') {
      <div class="col">
        <div class="qn-question">{{category.labeltext}}</div>
        <div class="qn-answer">{{category.response}}</div>
      </div>
      }
      }
    </div>
  </div>
  }
  @if(isSupervisorPosition) {
  @if(safetyFinalResponse.length > 0) {
  <div id="safety_section" class="container-header">
    <div class="row m-sapcing-10">
      <div class="col safety-section-header m-2">
        <div class="col-md-auto"><span id="safety-icon" class="icon-sec safety-bg category-sec">S</span></div>
        <div class="col-md-9"> <span id="safety-head" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
        <div class="col-md-2 ">
          <div id="safety-count" class="safety-mask-issue-tag justify-content-center align-items-center d-flex">{{reportedDefectSafetyCount}}</div>
        </div>
      </div>
  
    </div>
   
  
  </div>
  <app-safety-previous [reportedDefectArray]="reportedDefectArray"  [safetyResponse]="safetyFinalResponse" [isSupervisorPosition]="isSupervisorPosition"
    [isOperatorPosition]="isOperatorPosition" [roleName]="roleName" [selectedPlantCode]="selectedPlantCode" [positionCode]="positionCode"></app-safety-previous>
  }
  
  @if(qualityFinalResponse && qualityFinalResponse.length) {
  <div id="quality_section" class="container-header">
    <div class="row">
      <div class="col quality-section-header m-2">
        <div class="col-md-auto"><span id="quality-icon" class="icon-sec quality-bg category-sec">Q</span></div>
        <div class="col-md-9"><span id="quality-head" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
        <div class="col-md-2 d-flex justify-content-end">
          <div id="quality-count" class="quality-mask-issue-tag justify-content-center align-items-center d-flex">{{reportedDefectQualityCount}}</div>
        </div>
      </div>
    </div>
  </div>
  <app-quality-previous [reportedDefectArray]="reportedDefectArray"  [qualityResponse]="qualityFinalResponse" [roleName]="roleName"
    [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition"
    [positionCode]="positionCode" [selectedPlantCode]="selectedPlantCode"></app-quality-previous>
  }
  
  @if(deliveryFinalResponse && deliveryFinalResponse.length ) {
  <div id="delivery_section" class="container-header">
    <div class="row">
      <div class="col delivery-section-header m-2">
        <div class="col-md-auto"><span id="delivery-icon" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
        <div class="col-md-9"><span id="delivery-head" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
      
      </div>
    </div>
  </div>
  <app-delivery-previous [deliveryResponse]="deliveryFinalResponse" [roleName]="roleName"
  [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition"
  [positionCode]="positionCode" [selectedPlantCode]="selectedPlantCode"></app-delivery-previous>
  }
  
  
  @if(costFinalResponse.length > 0) {
  <div id="cost_section" class="container-header">
    <div class="row">
      <div class="col quality-section-header m-2">
        <div class="col-md-auto"><span id="cost-icon" class="icon-sec cost-bg category-sec">C</span></div>
        <div class="col-md-9"> <span id="cost-head" class="heading-span">{{ "app.previous_hoff.cost" | translate }}</span></div>
       
      </div>
    </div>
  </div>
  <app-cost-previous  [positionCode]="positionCode" [costResponse]="costFinalResponse" [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition" [selectedPlantCode]="selectedPlantCode"></app-cost-previous>
  }
  
  @if(peopleFinalResponse.length > 0) {
  <div id="people_section" class="container-header">
    <div class="row">
      <div class="col quality-section-header m-2">
        <div class="col-md-auto"><span id="people-icon" class="icon-sec people-bg category-sec">P</span></div>
        <div class="col-md-9"> <span id="people-head" class="heading-span">{{ "app.previous_hoff.people" | translate }}</span></div>
      
      </div>
    </div>
  </div>
  <app-people-previous [selectedPlantCode]="selectedPlantCode" [peopleResponse]="peopleFinalResponse" [positionCode]="positionCode"></app-people-previous>
  }
  
  @if(environmentFinalResponse.length > 0) {
  <div id="environment_section" class="container-header">
    <div class="row">
      <div class="col quality-section-header m-2">
        <div class="col-md-auto"><span id="environment-icon" class="icon-sec env-bg category-sec">E</span></div>
        <div class="col-md-9"> <span id="environment-head" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span></div>
       
      </div>
    </div>
  </div>
  <app-environment-previous [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition" [selectedPlantCode]="selectedPlantCode" [envResponse]="environmentFinalResponse" [positionCode]="positionCode"></app-environment-previous>
  }
  
  } @else if(isOperatorPosition) {
  
  @if(safetyFinalResponse) {
  <div id="safety_section" class="container-header">
    <div class="row m-sapcing-10">
      <div class="col safety-section-header m-2">
        <div class="col-md-auto"><span id="safety-icon" class="icon-sec safety-bg category-sec">S</span></div>
        <div class="col-md-9"> <span id="safety-head" class="heading-span">{{ "app.previous_hoff.safety" | translate }}</span></div>
        <div class="col-md-2 ">
          <div id="safety-count" class="safety-mask-issue-tag justify-content-center align-items-center d-flex font-bold">
            {{reportedDefectSafetyCount}}
          </div>
        </div>
      </div>
  
    </div>
   
  
  </div>
  <app-safety-previous  [reportedDefectArray]="reportedDefectArray" [safetyResponse]="safetyFinalResponse" [roleName]="roleName" [positionCode]="positionCode"
    [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition" [selectedPlantCode]="selectedPlantCode"></app-safety-previous>
  }
  @if(qualityFinalResponse) {
  <div id="quality_section" class="container-header">
    <div class="row">
      <div class="col quality-section-header m-2">
        <div class="col-md-auto"><span id="quality-icon" class="icon-sec quality-bg category-sec">Q</span></div>
        <div class="col-md-9"><span id="quality-head" class="heading-span">{{ "app.previous_hoff.quality" | translate }}</span></div>
        <div class="col-md-2 d-flex justify-content-end">
          <div id="quality-count" class="quality-mask-issue-tag justify-content-center align-items-center d-flex font-bold">
            {{reportedDefectQualityCount}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-quality-previous [reportedDefectArray]="reportedDefectArray"  [qualityResponse]="qualityFinalResponse" [roleName]="roleName"
    [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition"
    [positionCode]="positionCode" [selectedPlantCode]="selectedPlantCode"></app-quality-previous>
  }

  @if(deliveryFinalResponse.length > 0 && selectedPlantCode !== 'HAR') {
    <div id="delivery_section" class="container-header">
      <div class="row">
        <div class="col delivery-section-header m-2">
          <div class="col-md-auto"><span id="delivery-icon" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
          <div class="col-md-9"><span id="delivery-head" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
        
        </div>
      </div>
    </div>
    <app-delivery-previous [deliveryResponse]="deliveryFinalResponse" [roleName]="roleName"
    [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition"
    [positionCode]="positionCode" [selectedPlantCode]="selectedPlantCode"></app-delivery-previous>
    }

  @if(otherIssueFinalResponse && positionCode === 'MR' && selectedPlantCode !=='MAT') {
    <div id="otherIssue_section" class="container-header">
      <div class="row">
        <div class="col other-issue-section-header m-2">
          <div class="col-md-9"><span id="other-issue-section-head" class="heading-span">{{ "app.previous_hoff.other_issues" | translate }}</span></div>
          <div class="col-md-2 d-flex justify-content-end">
          </div>
        </div>
      </div>
    </div>
    
    <app-other-issues-previous [otherIssueResponse]="otherIssueFinalResponse" [roleName]="roleName"
      [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition"
      [positionCode]="positionCode"></app-other-issues-previous>
    }

  @if(centerlineFinalResponse && centerlineFinalResponse.length && positionCode !== 'PSA') {
    
  <div id="CLDeviation_section" class="container-header">
    <div class="row">
      <div class="col delivery-section-header m-2">
        <div class="col-md-auto"><span id="CLDeviation_icon" class="icon-sec delivery-bg category-sec text-black">D</span></div>
        <div class="col-md-9"><span id="CLDeviation_head" class="heading-span">{{ "app.previous_hoff.centerline_deviations" | translate }}</span></div>
        <div class="col-md-2 d-flex justify-content-end">
          <div id="CLDeviation_Count" class="yellow-mask-issue-tag justify-content-center align-items-center d-flex font-bold">
             {{getSummaryCountCLD()}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-centerline-deviations [reportedCLDeviationArray]="reportedCLDeviationArray" [centerlineControls]="centerlineControls" [centerlineResponse]="centerlineFinalResponse" [positionCode]="positionCode" [selectedPlantCode]="selectedPlantCode"></app-centerline-deviations>
  }
  @if(cidFinalResponse && selectedPlantCode == 'MAT' && ( positionCode !== 'CR' && positionCode !== 'QC'  && positionCode !== 'AFTR'  && positionCode !== 'NTYR'  && positionCode !== 'CC' && positionCode !== 'CM' )) {
    <div id="cid_section" class="container-header">
      <div class="row">
        <div class="col delivery-section-header m-2">
          <div class="col-md-auto"><span id="cid-icon" class="icon-sec delivery-bg category-sec text-black">D</span></div>
          <div class="col-md-9"><span id="cid-head" class="heading-span">{{ "app.previous_hoff.cid" | translate }}</span></div>
          <div class="col-md-2 d-flex justify-content-end">
            <div id="cid-count" class="yellow-mask-issue-tag justify-content-center align-items-center d-flex font-bold">
              <i class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-cid [cidResponse]="cidFinalResponse" [CIDdata]="CIDdata"></app-cid>
    }
    @else if(cidFinalResponse && (positionCode !== 'MR' &&  positionCode !== 'CR' && positionCode !== 'PSA' && selectedPlantCode !== 'MAT') && !((positionCode === 'PCMPN' || positionCode === 'FORK' || positionCode === 'ATSPL' ) && selectedPlantCode === 'BUR') && !( (positionCode === 'PKGL1' || positionCode === 'PKGL2' || positionCode === 'DCIL1' || positionCode === 'DCIL2'|| positionCode === 'NTRL1'|| positionCode === 'NTRL2' || positionCode === 'PLEBL1'  || positionCode === 'PLEBL2' || positionCode === 'LPL2' || positionCode === 'LPL1') && selectedPlantCode === 'HAR') &&  positionCode !== 'FRKOPR' && !(selectedPlantCode === 'BUR2' && (positionCode === 'PKGCMPN' || positionCode === 'AUTOSPLT'))) {
    <div id="cid_section" class="container-header">
    <div class="row">
      <div class="col delivery-section-header m-2">
        <div class="col-md-auto"><span id="cid-icon" class="icon-sec delivery-bg category-sec text-black">D</span></div>
        <div class="col-md-9"><span id="cid-head" class="heading-span">{{ "app.previous_hoff.cid" | translate }}</span></div>
        <div class="col-md-2 d-flex justify-content-end">
          <div id="cid-count" class="yellow-mask-issue-tag justify-content-center align-items-center d-flex font-bold">
            <i class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-cid [cidResponse]="cidFinalResponse" [CIDdata]="CIDdata"></app-cid>
  }
  @if(deliveryFinalResponse.length > 0 && selectedPlantCode == 'HAR' && (positionCode.includes('ENSM') || positionCode ==='PLEBL1' || positionCode ==='PLEBL2' || positionCode ==='PKGL1' || positionCode ==='PKGL2' ||  positionCode ==='BTR'
  || positionCode ==='TRPL1' || positionCode === 'FRMS' || positionCode ==='TRPL2' || positionCode ==='EBFLK' || positionCode ==='LPL1' || positionCode ==='LPL2' || positionCode ==='PLTRL1' || positionCode ==='PLTRL2' || positionCode ==='MLFR1'  || positionCode ==='MLFR2' || positionCode=='MLGFLK' || positionCode === 'FTRL1' || positionCode === 'FTRL2'
  || positionCode === 'CTXL1' || positionCode === 'CTXL2' || positionCode=='EPRL1'  || positionCode=='EPRL2' || positionCode.includes('CYLRL'))) {
    <div id="delivery_section" class="container-header">
      <div class="row">
        <div class="col delivery-section-header m-2">
          <div class="col-md-auto"><span id="delivery-icon" class="icon-sec delivery-bg category-sec text-black ">D</span></div>
          <div class="col-md-9"><span id="delivery-head" class="heading-span">{{ "app.previous_hoff.delivery" | translate }}</span></div>
        
        </div>
      </div>
    </div>
    <app-delivery-previous [deliveryResponse]="deliveryFinalResponse" [roleName]="roleName"
    [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition"
    [positionCode]="positionCode" [selectedPlantCode]="selectedPlantCode"></app-delivery-previous>
    }
  @if(equipmentStopsFinalResponse && equipmentStopsFinalResponse?.length) {
  <div id="equipment-stops_section" class="container-header">
    <div class="row">
      <div class="col delivery-section-header m-2">
        <div class="col-md-auto"><span id="equipment-stops-icon" class="icon-sec delivery-bg category-sec text-black">D</span></div>
        <div class="col-md-9"><span id="equipment-stops-head" class="heading-span">{{ "app.previous_hoff.equipment_stops" | translate }}</span></div>
        <div class="col-md-2 d-flex justify-content-end">
          <div id="equipment-stops-count" class="yellow-mask-issue-tag justify-content-center align-items-center d-flex font-bold">
              {{getSummaryCount("EQUIPMENT STOPS")}} 
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-equipment-stops [equStopsResponse]="equipmentStopsFinalResponse" [equipmentStopsControls]="equipmentStopsControls" [reportedRTDuetDefectObj]="reportedRTDuetDefectObj" [selectedPlantCode]="selectedPlantCode" [positionCode]="positionCode"></app-equipment-stops>
  }
  @if(equipmentDefectsFinalResponse && equipmentDefectsFinalResponse.length){
    <div id="equipment-defects_section" class="container-header">
      <div class="row">
        <div class="col delivery-section-header m-2">
          <div class="col-md-auto"><span id="equipment-defects-icon" class="icon-sec delivery-bg category-sec text-black">D</span></div>
          <div class="col-md-9"><span id="equipment-defects-head" class="heading-span">{{ "app.previous_hoff.equipment_defects" | translate }}</span></div>
          <div class="col-md-2 d-flex justify-content-end">
            <div id="equipment-defects-count" class="yellow-mask-issue-tag justify-content-center align-items-center d-flex font-bold">{{reportedDefectEDCount}}</div>
          </div>
        </div>
      </div>
    </div>
    <app-equipment-defects [positionCode]="positionCode" [selectedPlantCode]="selectedPlantCode" [reportedDefectArray]="reportedDefectArray"  [equDefectResponse]="equipmentDefectsFinalResponse"></app-equipment-defects>  
  }
  
  @if(costFinalResponse && costFinalResponse.length) {
    <div id="cost_section" class="container-header">
      <div class="row" *ngIf="(selectedPlantCode !=='MAT') || (selectedPlantCode =='MAT' && isSupervisorPosition )">
        <div class="col quality-section-header m-2">
          <div class="col-md-auto"><span id="cost-icon" class="icon-sec cost-bg category-sec">C</span></div>
          <div class="col-md-9"> <span id="cost-head" class="heading-span">{{ "app.previous_hoff.cost" | translate }}</span></div>
         
        </div>
      </div>
    </div>
    <app-cost-previous  [positionCode]="positionCode" [costResponse]="costFinalResponse" [isSupervisorPosition]="isSupervisorPosition" [isOperatorPosition]="isOperatorPosition"  [selectedPlantCode]="selectedPlantCode"></app-cost-previous>
    }

    @if(peopleFinalResponse.length > 0) {
      <div id="people_section" class="container-header">
        <div class="row">
          <div class="col quality-section-header m-2">
            <div class="col-md-auto"><span id="people-icon" class="icon-sec people-bg category-sec">P</span></div>
            <div class="col-md-9"> <span id="people-head" class="heading-span">{{ "app.previous_hoff.people" | translate }}</span></div>
          
          </div>
        </div>
      </div>
      <app-people-previous [selectedPlantCode]="selectedPlantCode" [peopleResponse]="peopleFinalResponse" [positionCode]="positionCode"></app-people-previous>
      }

  @if(environmentFinalResponse && environmentFinalResponse.length>0 && (selectedPlantCode == 'CDL' || selectedPlantCode == 'MAT'  || selectedPlantCode == 'BUR' ||selectedPlantCode == 'BUR1' || selectedPlantCode == 'HAR'  || selectedPlantCode == 'BUR2')) {
    <div id="environment_section" class="container-header">
      <div class="row">
        <div class="col quality-section-header m-2">
          <div class="col-md-auto"><span id="environment-icon" class="icon-sec env-bg category-sec">E</span></div>
          <div class="col-md-9"> <span id="environment-head" class="heading-span">{{ "app.previous_hoff.environment" | translate }}</span></div>
          @if((selectedPlantCode == 'BUR' || selectedPlantCode == 'BUR2' || selectedPlantCode == 'BUR1') && (positionCode == 'PSA' || positionCode === 'PKGCMPN')){
          }@else{
            <div class="col-md-2 ">
              <div id="environment-count" class="defect-sec  env-bg  justify-content-center align-items-center d-flex">{{reportedDefectENVCount}}</div>
            </div>
          }
        </div>
         
          
      </div>
    </div>  
    <app-environment-previous [isSupervisorPosition]="isSupervisorPosition"  [selectedPlantCode]="selectedPlantCode" [isOperatorPosition]="isOperatorPosition" [reportedDefectArray]="reportedDefectArray"  [envResponse]="environmentFinalResponse" [positionCode]="positionCode"></app-environment-previous>
    
  }
  }
  
  
  